<template>
  <section class="page-project">
    <template v-if="projectObj">
      <div
        class="banner"
        :style="{ 'background-image': `url(${projectObj.bannerImg})` }"
      >
        <p class="title">
          <span>{{ projectObj.nameEn }}</span>
        </p>
      </div>
      <div class="content" :class="{ fold: isFold }">
        <p>{{ projectObj.descriptionEn }}</p>
      </div>
      <div class="look-more" v-if="projectObj.descriptionEn.length > 100">
        <div class="opacity" v-if="isFold">
          <img src="//static.hoolihome.com/global-jwp/assets/m/text-mask.png" />
        </div>
        <div class="btn" @click="handleLookMore">
          <span>{{ btnText }}</span>
          <img
            :src="
              isFold
                ? '//static.hoolihome.com/global-jwp/assets/m/arrow-more-down.png'
                : '//static.hoolihome.com/global-jwp/assets/m/arrow-more-up.png'
            "
          />
        </div>
      </div>
      <div class="infos">
        <p class="title">
          <span class="van-hairline--bottom">Project Information</span>
        </p>
        <ul class="boxs">
          <li class="box">
            <van-image
              width="24"
              height="24"
              lazy-load
              src="//static.hoolihome.com/global-jwp/assets/m/project/icon-location.png"
            />
            <p class="k">Project Location</p>
            <p class="v">{{ projectObj.propertyLocationEn }}</p>
          </li>
          <li class="box">
            <van-image
              width="24"
              height="24"
              lazy-load
              src="//static.hoolihome.com/global-jwp/assets/m/project/icon-year.png"
            />
            <p class="k">Project Rights</p>
            <p class="v">{{ projectObj.propertyRightYearsEn }}</p>
          </li>
          <li class="box">
            <van-image
              width="24"
              height="24"
              lazy-load
              src="//static.hoolihome.com/global-jwp/assets/m/project/icon-form.png"
            />
            <p class="k">Building Type</p>
            <p class="v">{{ projectObj.architecturalFormEn }}</p>
          </li>
          <li class="box">
            <van-image
              width="24"
              height="24"
              lazy-load
              src="//static.hoolihome.com/global-jwp/assets/m/project/icon-floors.png"
            />
            <p class="k">Total Floors</p>
            <p class="v">{{ projectObj.totalStoreysEn }}</p>
          </li>
          <li class="box">
            <van-image
              width="24"
              height="24"
              lazy-load
              src="//static.hoolihome.com/global-jwp/assets/m/project/icon-layout.png"
            />
            <p class="k">Unit Types</p>
            <p class="v">{{ projectObj.apartmentSpaceEn }}</p>
          </li>
        </ul>
      </div>
      <div class="displays project" v-if="projectDisplayImgs.length > 0">
        <div
          class="hd more"
          v-if="projectDisplayImgs.length > 3"
          @click="handleDisplayMore('project')"
        >
          <span class="text">Project Photos</span>
          <img
            class="more"
            src="//static.hoolihome.com/global-jwp/assets/m/arrow-more-right.png"
          />
        </div>
        <div class="hd" v-else>
          <span class="text">Project Photos</span>
        </div>
        <div class="scroll-wrap">
          <ul class="boxs">
            <template v-for="(item, index) in projectDisplayImgs">
              <li
                class="box"
                :key="index"
                v-if="index <= 2"
                @click="handleImagePreview('project', index)"
              >
                <van-image
                  width="223"
                  height="144"
                  fit="cover"
                  lazy-load
                  :src="item"
                />
              </li>
            </template>
          </ul>
        </div>
      </div>
      <div class="displays layout" v-if="apartmentDisplayImgs.length > 0">
        <div
          class="hd more"
          v-if="apartmentDisplayImgs.length > 3"
          @click="handleDisplayMore('layout')"
        >
          <span class="text">Floor Plans</span>
          <img
            class="more"
            src="//static.hoolihome.com/global-jwp/assets/m/arrow-more-right.png"
          />
        </div>
        <div class="hd" v-else>
          <span class="text">Floor Plans</span>
        </div>
        <div class="scroll-wrap">
          <ul class="boxs">
            <template v-for="(item, index) in apartmentDisplayImgs">
              <li
                class="box"
                :key="index"
                v-if="index <= 2"
                @click="handleImagePreview('layout', index)"
              >
                <van-image
                  width="128"
                  height="128"
                  fit="cover"
                  lazy-load
                  :src="item"
                />
              </li>
            </template>
          </ul>
        </div>
      </div>
      <div class="recommends" v-if="projectObj.recommendProjects.length > 0">
        <p class="title">
          <span class="van-hairline--bottom">More Recommendations</span>
        </p>
        <project-list :projects="projectObj.recommendProjects"></project-list>
      </div>
      <display-imgs
        v-if="displayType"
        :display-type="displayType"
        :imgs-arr="imgsArr"
        @close="displayType = ''"
        @handle-image-preview="handleImagePreview"
      ></display-imgs>
    </template>
  </section>
</template>

<script>
import { ImagePreview } from "vant";
import ProjectList from "@/components/project/ProjectList";
import DisplayImgs from "@/components/project/DisplayImgs";

export default {
  components: { ProjectList, DisplayImgs },
  data() {
    return {
      isFold: true,
      btnText: "View more",
      projectObj: null,
      displayType: "",
      imgsArr: []
    };
  },
  watch: {
    projectObj(val) {
      if (val) {
        document.title = val.nameEn;
      }
    }
  },
  computed: {
    // 项目展示图
    projectDisplayImgs() {
      try {
        if (this.projectObj) {
          return JSON.parse(this.projectObj.projectDisplayImgs);
        }
        // eslint-disable-next-line no-empty
      } catch {}
      return [];
    },
    // 户型图
    apartmentDisplayImgs() {
      try {
        if (this.projectObj) {
          return JSON.parse(this.projectObj.apartmentDisplayImgs);
        }
        // eslint-disable-next-line no-empty
      } catch {}
      return [];
    }
  },
  created() {
    this.loadData();
  },
  methods: {
    handleLookMore() {
      if (this.isFold) {
        this.isFold = false;
        this.btnText = "Collapse";
      } else {
        this.isFold = true;
        this.btnText = "View more";
      }
    },
    handleDisplayMore(what) {
      this.displayType = what;
      if (what === "project") {
        this.imgsArr = this.projectDisplayImgs;
      } else if (what === "layout") {
        this.imgsArr = this.apartmentDisplayImgs;
      }
    },
    handleImagePreview(what, which) {
      if (what === "project") {
        ImagePreview({
          images: this.projectDisplayImgs,
          startPosition: which,
          closeable: true
        });
      } else if (what === "layout") {
        ImagePreview({
          images: this.apartmentDisplayImgs,
          startPosition: which,
          closeable: true
        });
      }
    },
    loadData() {
      const params = {};
      params.url = this.$apis.jwpInfo;
      params.data = {
        id: this.$route.params.projectId
      };
      this.$net.req(params).then(res => {
        if (res) {
          const { data } = res;
          if (data) {
            this.projectObj = data;
          }
        }
      });
    }
  }
};
</script>

<style lang="less" scoped>
.page-project {
  min-height: 100vh;
  .banner {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    height: 360px;
    background-color: #ffffff;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    .title {
      padding-bottom: 36px;
      span {
        padding-bottom: 4px;
        font-size: 20px;
        font-weight: bold;
        color: rgba(255, 255, 255, 1);
        border-bottom: 2px rgba(255, 255, 255, 0.5) solid;
      }
    }
  }
  .content {
    padding: 16px 22px;
    transition: all 0.3s;
    &.fold {
      height: 71px;
      overflow: hidden;
    }
    p {
      font-size: 14px;
      color: rgba(38, 38, 38, 1);
      line-height: 20px;
    }
  }
  .look-more {
    padding: 0 22px;
    .opacity {
      height: 20px;
      margin: -20px 0 16px;
      img {
        width: 100%;
      }
    }
    .btn {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 34px;
      border-radius: 4px;
      border: 1px solid rgba(38, 38, 38, 1);
      &:active {
        opacity: 0.8;
      }
      span {
        font-size: 12px;
        font-weight: 400;
        color: rgba(38, 38, 38, 1);
      }
      img {
        width: 8px;
        margin-left: 8px;
      }
    }
  }
  .infos {
    padding-top: 40px;
    .title {
      padding-bottom: 27px;
      text-align: center;
      span {
        padding-bottom: 4px;
        font-size: 20px;
        font-weight: 300;
        color: rgba(1, 61, 125, 1);
        &::after {
          border-color: rgba(1, 61, 125, 1);
        }
      }
    }
    .boxs {
      display: flex;
      justify-content: space-between;
      padding: 0 22px;
      .box {
        width: 65px;
        text-align: center;
        .k {
          margin-top: 4px;
          font-size: 12px;
          font-weight: 400;
          color: rgba(38, 38, 38, 1);
          line-height: 17px;
        }
        .v {
          margin-top: 2px;
          font-size: 10px;
          font-family: PingFangSC-Light, PingFang SC;
          font-weight: 300;
          color: rgba(102, 102, 102, 1);
          line-height: 14px;
        }
      }
    }
  }
  .displays {
    padding-top: 40px;
    &.layout {
      .scroll-wrap {
        .boxs {
          .box {
            flex-shrink: 0;
            overflow-y: hidden;
            .van-image {
              border-radius: 4px;
              border: 1px solid rgba(55, 55, 55, 1);
            }
          }
        }
      }
    }
    .hd {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-bottom: 27px;
      &.more {
        &:active {
          opacity: 0.8;
        }
      }
      .text {
        font-size: 20px;
        font-weight: 300;
        color: rgba(1, 61, 125, 1);
        line-height: 28px;
      }
      .more {
        width: 15px;
        margin-left: 4px;
      }
    }
    .scroll-wrap {
      width: 100vw;
      overflow-x: scroll;
      .boxs {
        display: flex;
        padding-left: 22px;
        .box {
          flex-shrink: 0;
          padding-right: 14px;
          overflow-y: hidden;
          &:last-child {
            padding-right: 22px;
          }
          .van-image {
            overflow: hidden;
            border-radius: 4px;
          }
        }
      }
    }
  }
  .recommends {
    padding: 40px 0;
    .title {
      padding-bottom: 27px;
      text-align: center;
      span {
        padding-bottom: 4px;
        font-size: 20px;
        font-weight: 300;
        color: rgba(1, 61, 125, 1);
        &::after {
          border-color: rgba(1, 61, 125, 1);
        }
      }
    }
  }
}
</style>

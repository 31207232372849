<template>
  <section :class="['display-imgs', displayType]">
    <div class="close" @click="$emit('close')">
      <i
        role="button"
        class="van-icon van-icon-clear van-image-preview__close-icon van-image-preview__close-icon--top-right"
      ></i>
    </div>
    <div class="imgs-wrap">
      <p class="title">
        <span class="van-hairline--bottom" v-if="displayType === 'project'"
          >Project Photos</span
        >
        <span class="van-hairline--bottom" v-else-if="displayType === 'layout'"
          >Floor Plans</span
        >
      </p>
      <ul class="boxs" v-if="displayType === 'project'">
        <li
          class="box"
          v-for="(item, index) in imgsArr"
          :key="index"
          @click="$emit('handle-image-preview', displayType, index)"
        >
          <van-image fit="cover" lazy-load :src="item" />
        </li>
      </ul>
      <ul class="boxs" v-else-if="displayType === 'layout'">
        <li
          class="box"
          v-for="(item, index) in imgsArr"
          :key="index"
          @click="$emit('handle-image-preview', displayType, index)"
        >
          <van-image fit="cover" lazy-load :src="item" />
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    displayType: {
      type: String,
      required: true
    },
    imgsArr: {
      type: Array,
      required: true
    }
  }
};
</script>

<style lang="less" scoped>
.display-imgs {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 99;
  background: #fff;
  .close {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 100;
    padding: 26px;
  }
  .imgs-wrap {
    padding: 40px 0 20px;
    .title {
      padding-bottom: 27px;
      text-align: center;
      span {
        padding-bottom: 4px;
        font-size: 20px;
        font-weight: 300;
        color: rgba(1, 61, 125, 1);
        &::after {
          border-color: rgba(1, 61, 125, 1);
        }
      }
    }
    .boxs {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding: 0 22px;
      max-height: calc(100vh - 130px);
      overflow-y: auto;
      .box {
        margin-bottom: 20px;
        &:nth-child(2n + 2) {
          margin-left: 10px;
        }
        .van-image {
          width: 156px;
          height: 156px;
          border-radius: 4px;
          overflow: hidden;
          border: 1px solid rgba(55, 55, 55, 1);
        }
      }
    }
  }
}
</style>
